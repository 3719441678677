import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { VaughanCommunityHistory } from 'components/VaughanCommunityHistory'
import { VaughanCommunityGeo } from 'components/VaughanCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { VaughanCommunityHero } from 'components/VaughanCommunityHero'
import { VaughanCommunityInformation } from 'components/VaughanCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchVaughan'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentVaughan'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const Vaughan: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv className="cityPage">
        <PropertiesComponent defaultCity="Vaughan" />
        <Contact />
    </FlexDiv>
    </EllicotLayout>
  )
}

export default Vaughan
